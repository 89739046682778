import React, { useEffect, useRef, useState } from 'react';
import { Page, Content } from "@backstage/core-components";
import { Grid, Paper, Slide, Step, StepButton, Stepper } from '@material-ui/core';
import { HomePageStyles } from "../../../../assets/styles/HomePageStyles";
import { CustomTransition, DemoCard } from '../../../customUIComponents';

// Demo Page Content
import { DemoData } from '../../assets/data';

export const DemoPage = () => {
    const classes = HomePageStyles();

    // Recreate Demo Content with Refs
    const DemoDataWithRefs = DemoData.sort((a, b) => a.order > b.order ? 1 : -1).map((item) => (
        {
            ...item,
            demoRef: useRef<null | HTMLDivElement>(null)
        }
    ))

    // States
    const [activeStep, setActiveStep] = useState(0);

    // Scroll Event function
    const handleScroll = () => {
        for (let i = 0; i < DemoDataWithRefs.length; i++) {

            // Get current Item position and height
            let currentItemPosition = DemoDataWithRefs[i].demoRef.current?.offsetTop;
            let currentItemHeight = DemoDataWithRefs[i].demoRef.current?.offsetHeight;
            let buffer = currentItemHeight ? currentItemHeight / 2 : 100;

            // Get last item position height
            let lastItemPosition = DemoDataWithRefs[DemoDataWithRefs.length - 1].demoRef.current?.offsetTop;
            let lastItemHeight = DemoDataWithRefs[DemoDataWithRefs.length - 1].demoRef.current?.offsetHeight;

            // Set to last item if user scrolled to the bottom if not, check scroll position and set to the correct step
            if (lastItemPosition && lastItemHeight && window.scrollY >= lastItemPosition - lastItemHeight) {
                setActiveStep(DemoDataWithRefs.length - 1);
                break;
            } else if (currentItemPosition && window.scrollY <= currentItemPosition + buffer && window.scrollY >= currentItemPosition - buffer) {
                setActiveStep(i);
                break;
            }
        }

    }

    // Listen Scroll Event
    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [])

    return (
        <div className={classes.backstagePageOverride}>
            <Page themeId="home">
                <main >
                    <Content>
                        <Grid container>
                            <Grid item xs={12} md={3}>
                                <Slide direction='up' appear={true} in={true} timeout={400}>
                                    <div style={{ position: "sticky", top: 0, padding: "10px 0" }}>
                                        <Paper>
                                            <Stepper nonLinear activeStep={activeStep} orientation="vertical" style={{ borderRadius: "4px" }}>
                                                {DemoDataWithRefs.map((item, index) => (

                                                    <Step key={index}>
                                                        <StepButton onClick={() => {
                                                            item.demoRef.current?.scrollIntoView({ behavior: "smooth" });
                                                        }}>{item.title}</StepButton>
                                                    </Step>

                                                ))}
                                            </Stepper>
                                        </Paper>
                                    </div>
                                </Slide>
                            </Grid>
                            <Grid item xs={12} md={9}>
                                {DemoDataWithRefs.map((item, index) => (
                                    <CustomTransition type="slide" direction='up' appear={true} in={true} timeout={400} delay={index * 200} key={index}>
                                        <div style={{ padding: "10px 0" }} ref={item.demoRef}>
                                            <DemoCard
                                                id={item.id}
                                                title={item.title}
                                                content={item.content}
                                                image={item.image ? item.image : undefined}
                                                src={item.src ? item.src : undefined}
                                                order={item.order}
                                            />
                                        </div>
                                    </CustomTransition>
                                ))}
                            </Grid>
                        </Grid>
                    </Content>
                </main>

            </Page>
        </div>
    )
}