import React from 'react';
import {Box, Container, Link, Typography} from "@material-ui/core";

const Copyright = () => (
    <div>
        <Typography variant="body2" color={"primary"} align="center" style={{padding: '20px 0'}}>
            {'Copyright © '}
            <Link color="inherit" href="https://backstage.pearsondev.com">
                Pearson Backstage Authors
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    </div>
)

interface FooterProps {
    description: string;
    title: string;
}

export const Footer = (props: FooterProps) => {
    const { description, title } = props;
    return (
        <Box component="footer" style={{ height: '60px', position: 'relative', clear: 'both', top: '60px'}}>
            <Container maxWidth="lg">
                <Typography variant="h6" align="center" gutterBottom>
                    {title}
                </Typography>
                <Typography
                    variant="subtitle1"
                    align="center"
                    color={"primary"}
                    component="p"
                >
                    {description}
                </Typography>
                <Copyright />
            </Container>
        </Box>
    )

}