import { Card, CardContent, CardHeader, CardMedia, Grid, Typography } from "@material-ui/core";
import React from "react";
import { DemoCardStyles } from "../../../assets/styles";

export interface DemoCardProps {
    id:string,
    title:string,
    content: React.ReactNode,
    src?:string,
    image?:string,
    order:number
}

export const DemoCard = (props: DemoCardProps) => {

    const classes = DemoCardStyles()
    return(
        <Card id={props.id} className={classes.DemoCard}>
            <CardHeader title={props.title} className={classes.DemoCardTitle}/>
            <Grid container>
                <Grid item xs={12} md={6}>
                    <CardContent>
                        <Typography variant="body1" color="textSecondary">{props.content}</Typography>
                    </CardContent>
                </Grid>
                <Grid item xs={12} md={6}>
                    {props.image?
                        <CardMedia
                        component="img"
                        image={props.image}
                        alt={props.title}
                        className={classes.DemoCardMedia}
                    />
                    : <CardMedia
                    component="video"
                    controls
                    src={props.src}
                    className={classes.DemoCardMedia}
                />
                    }
                </Grid>
            </Grid>
        </Card>
    )
}