/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { PropsWithChildren } from 'react';
import { Link, makeStyles, useTheme } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import MapIcon from '@material-ui/icons/MyLocation';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import CreateComponentIcon from '@material-ui/icons/AddCircleOutline';
import { NavLink } from 'react-router-dom';
import {
  Settings as SidebarSettings,
  UserSettingsSignInAvatar,
} from '@backstage/plugin-user-settings';
import { SidebarSearchModal } from '@backstage/plugin-search';
import {
  Sidebar,
  sidebarConfig,
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarSubmenu,
  SidebarSubmenuItem,
  // SidebarScrollWrapper,
  SidebarSpace,
  useSidebarOpenState,
} from '@backstage/core-components';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
// import MoneyIcon from '@material-ui/icons/MonetizationOn';
import LogoFull from './LogoFull';
import LogoIcon from './LogoIcon';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import CloudQueueIcon from '@material-ui/icons/CloudQueue';
import EditIcon from '@material-ui/icons/Edit';
import PowerIcon from '@material-ui/icons/Power';
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';
import LinkIcon from '@material-ui/icons/Link';
import BuildIcon from '@material-ui/icons/Build';
import ExtensionIcon from '@material-ui/icons/Extension';
import GroupIcon from '@material-ui/icons/Group';
import { Shortcuts } from '@backstage/plugin-shortcuts';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import StorefrontIcon from '@material-ui/icons/Storefront';
import AccountTreeIcon from '@material-ui/icons/AccountTree';

const useSidebarLogoStyles = makeStyles({
  root: {
    width: sidebarConfig.drawerWidthClosed,
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
  },
  link: {
    width: sidebarConfig.drawerWidthClosed,
    marginLeft: 24,
  },
});

const SidebarLogo = () => {
  const logoStyleClasses = useSidebarLogoStyles();
  const { isOpen } = useSidebarOpenState();

  return (
    <div className={logoStyleClasses.root}>
      <Link
        component={NavLink}
        to="/"
        underline="none"
        className={logoStyleClasses.link}
        aria-label="Home"
      >
        {isOpen ? <LogoFull /> : <LogoIcon />}
      </Link>
    </div>
  );
};

const quickLinks = [
  {
    to: 'https://dashboards.pearsonserviceinsights.com/',
    title: 'PSI Dashboards',
  },
  {
    to: 'https://pearson.grafana.net/',
    title: 'Grafana Cloud',
  },
  {
    to: 'https://pearsonai.pagerduty.com/',
    title: 'Pagerduty',
  },
  {
    to: 'https://one.newrelic.com/',
    title: 'New Relic',
  },
  {
    to: 'https://splunk-aws.pearsondev.com/',
    title: 'Splunk Cloud',
  },
  {
    to: 'https://pearsoneducationinc.sharepoint.com/',
    title: 'Sharepoint',
  },
  {
    to: '/jenkins',
    title: 'Jenkins',

  },
  {
    to: 'https://gitlab.com/pearsontechnology',
    title: 'Gitlab',
  },
  {
    to: 'https://gilligan.pearsondev.com',
    title: 'Gilligan Gitlab',
  },
  {
    to: 'https://bitbucket.pearson.com/',
    title: 'Bitbucket',
  },
  {
    to: 'https://jira.assessment.pearson.com',
    title: 'Assessment Jira',
  },
  {
    to: 'https://agile-jira.pearson.com/',
    title: 'Agile Jira',
  },
  {
    to: 'https://confluence.assessment.pearson.com',
    title: 'Assessment Confluence',
  },
  {
    to: 'https://one-confluence.pearson.com',
    title: 'One Confluence',
  },
  {
    to: '/pop',
    title: 'Observability Pipeline',
  }]

export const Root = ({ children }: PropsWithChildren<{}>) => (
  <SidebarPage>
    <Sidebar disableExpandOnHover>
      <SidebarLogo />
      <SidebarGroup label="Search" icon={<SearchIcon />} to="/search">
        <SidebarSearchModal />
      </SidebarGroup>
      <SidebarDivider style={{ backgroundColor: useTheme().palette.sidebarDivider }} />
      <SidebarGroup label="Menu" icon={<MenuIcon />}>
        {/* Global nav, not org-specific */}
        <SidebarItem icon={HomeIcon} to="/" text="Home" />
        <SidebarItem icon={MenuBookIcon} text="Catalog">
          <SidebarSubmenu title="Catalog">
            <SidebarSubmenuItem title="Catalog" to="catalog" icon={MenuBookIcon} />
            <SidebarSubmenuItem title="Teams" to="/catalog?filters%5Bkind%5D=group&filters%5Buser%5D=all" icon={GroupIcon} />
            <SidebarSubmenuItem title="APIs" to="api-docs" icon={CloudQueueIcon} />
            <SidebarSubmenuItem title="Non-GPS API's" to="https://one-confluence.pearson.com/pages/viewpage.action?spaceKey=APIMgmt&title=API+Documentation" icon={ExtensionIcon} />
          </SidebarSubmenu>
        </SidebarItem>
        <SidebarItem icon={BuildIcon} text="Pearson Tools">
          <SidebarSubmenu title="Pearson Tools">
            <SidebarSubmenuItem title="Paved Road Services" to="/paved-road" icon={EmojiTransportationIcon} />
            <SidebarItem icon={AccountTreeIcon} to="/pagerduty-services" text="Pagerduty Service Mapping" />
            <SidebarSubmenuItem
              title="Quick Links"
              to="/6"
              icon={LinkIcon}
              dropdownItems={
                quickLinks
              }
            />
          </SidebarSubmenu>
        </SidebarItem>
        <SidebarItem icon={LibraryBooks} to="docs" text="Docs" />
        <SidebarItem icon={EditIcon} to="/blog" text="Blog" />
        <SidebarItem icon={PowerIcon} to="/plugins" text="Plugins" />
        <SidebarItem icon={CreateComponentIcon} to="create" text="Create..." />
        {/* End global nav */}
        <SidebarDivider style={{ backgroundColor: useTheme().palette.sidebarDivider }} />
        <SidebarItem icon={LiveHelpIcon} to="qeta" text="Memory Leak" />
        <SidebarItem icon={StorefrontIcon} to="bazaar" text="Bazaar" />
        <SidebarItem icon={BuildIcon} to="toolbox" text="ToolBox" />
        <SidebarItem icon={MapIcon} text="Radars">
          <SidebarSubmenu title="Radars">
            <SidebarSubmenuItem title="Tech Radar" to="tech-radar" icon={MapIcon} />
            <SidebarSubmenuItem title="Security Radar" to="security-radar" icon={MapIcon} />
          </SidebarSubmenu>
        </SidebarItem>
        {/*<SidebarItem icon={MoneyIcon} to="cost-insights" text="Cost Insights" />*/}
      </SidebarGroup>
      <SidebarDivider style={{ backgroundColor: useTheme().palette.sidebarDivider }} />
      <div className='plugin-shortcuts-scroll'>
        <Shortcuts />
      </div>
      <SidebarSpace />
      <SidebarDivider style={{ backgroundColor: useTheme().palette.sidebarDivider }} />
      <SidebarGroup
        label="Settings"
        icon={<UserSettingsSignInAvatar />}
        to="/settings"
      >
        <SidebarSettings />
      </SidebarGroup>
    </Sidebar>
    {children}
  </SidebarPage>
);
