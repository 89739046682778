import React from 'react';

export interface FAQDataItems {
    question: React.ReactNode,
    answer: React.ReactNode
}

export const FAQData: FAQDataItems[] = [
    {
        question: 'What is the purpose of Backstage?',
        answer: 'Backstage is a developer / engineering portal. A centralized system, or hub, that unifies all your infrastructure tooling, services, and documentation to create a streamlined development environment from end to end.'
    },
    {
        question: 'What tech stack does Backstage use?',
        answer: 'The codebase is a large-scale React application that uses TypeScript.'
    },
    {
        question: 'What is the infrastructure that runs Backstage?',
        answer: 'The infra runs in AWS on an EKS Fargate cluster. It is a typical three tier application. The DB we are using is Aurora PostgreSQL Serverless.'
    },
    {
        question: 'What is the end-to-end user flow? The happy path story.',
        answer:
            <span>
                There are three main user profiles for Backstage: the integrator, the contributor, and the software engineer.
                <ol>
                    <li>The <b>integrator</b> hosts the Backstage app and configures which plugins are available to use in the app.</li>
                    <li>The <b>contributor</b> adds functionality to the app by writing plugins.</li>
                    <li>The <b>software engineer</b> uses the app's functionality and interacts with its plugins.</li>
                </ol>
            </span>
    },
    {
        question: 
            <span>
                What is a "plugin" in Backstage?
            </span>
        ,
        answer:
            <span>
                Plugins are what provide the feature functionality in Backstage. They are used to integrate different systems into Backstage's frontend, so that the developer gets a consistent UX, no matter what tool or service is being accessed on the other side.
                <br /><br />
                Each plugin is treated as a self-contained web app and can include almost any type of content. Plugins all use a common set of platform APIs and reusable UI components. Plugins can fetch data either from the backend or an API exposed through the proxy.
            </span>
    },
    {
        question: "Why can't I dynamically install plugins without modifications to the app?",
        answer:
            <span>
                This decision is part of the core architecture and development flow of Backstage. Plugins have a lot of freedom in what they provide and how they are integrated into the app, and it would therefore add a lot of complexity to allow plugins to be integrated via configuration the same way as they can be integrated with code.
                <br /><br />
                By bundling all plugins and their dependencies into one app bundle it is also possible to do significant optimizations to the app load time by allowing plugins to share dependencies between each other when possible. This contributes to Backstage being fast, which is an important part of the user and developer experience.
            </span>
    },
    {
        question: 'How can I get involved?',
        answer:
            <span>
                Jump right in! Come help us fix some of the <a href='https://gitlab.com/pearsontechnology/IO/cdnr/products/pearson-backstage/backstage/-/issues' target='_blank'>early bugs and good first issues</a> Or write an open source plugin for Backstage, like this <a href='https://github.com/backstage/backstage/tree/master/plugins/lighthouse' target='_blank'>Lighthouse plugin</a>. See all the ways you can <a href='https://backstage.pearsondev.com/docs/default/Component/backstage' target='_blank'>contribute here</a>. We'd love to have you as part of the community.
            </span>
    }
]