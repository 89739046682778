/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
    RadarRing,
    RadarQuadrant,
    RadarEntry,
    TechRadarLoaderResponse,
    // TechRadarApi,
} from '@backstage/plugin-tech-radar';

const rings = new Array<RadarRing>();
rings.push({ id: 'use', name: '', color: '#93c47d' });
rings.push({ id: 'trial', name: '', color: '#93d2c2' });
rings.push({ id: 'assess', name: '', color: '#fbdb84' });
rings.push({ id: 'hold', name: '', color: '#efafa9' });

const quadrants = new Array<RadarQuadrant>();
quadrants.push({ id: 'tools', name: '' });
quadrants.push({ id: 'frameworks', name: '' });
quadrants.push({ id: 'languages', name: '' });
quadrants.push({ id: 'process', name: '' });

const entries = new Array<RadarEntry>();


export const SecurityRadar: TechRadarLoaderResponse = {
    entries,
    quadrants,
    rings,
};