import {makeStyles} from "@material-ui/core";

export const PaperLinkStyles = makeStyles((theme) => (
    {
        paper: {
            marginTop: "30px",
            "-webkit-transition": "background 0.5s, color 0.5s",
            transition: "background color 0.5s, color 0.5s",
            color: theme.palette.text.primary,
            "&:hover": {
                cursor: "pointer",
                background: theme.palette.primary.main,
                color: theme.palette.secondary.light,
                "& svg": {
                    transform: "translateX(10px)",
                }
            },
        },
        grid: {
            padding: "10px 30px"
        },
        navigateIcon: {
            transform: "translateX(0)",
            "-webkit-transition": "transform 0.5s ease-out",
            transition: "transform 0.5s ease-out",
        },
        // Use the below class on Parent of the Paper link to use the bordered Paper Link (Without Elevation)
        borderedPaperLink: {
            padding: "12px 12px",
            "& a": {
                "& div[class*=makeStyles-paper-]": {
                    boxShadow: "none",
                    padding: "6px 0",
                    margin: 0,
                    border: "0.2px solid " + theme.palette.divider
                }
            },
        }
    }
))

export const CustomAccordionStyles = makeStyles((theme) => ({
    accordionStyle: {
        boxShadow: "none",
        border: "0.1px solid " + theme.palette.divider,
        borderRadius: "4px",
        marginBottom: "5px !important",
        "&::before": {
            background: "none"
        },
        "&.Mui-expanded p[class*=summaryTitle]": {
            fontSize: '1.08rem'
        },
        "&:hover div[class*=MuiAccordionSummary-root]":{
            color: theme.palette.primary.main,
            "& span[class*=MuiIconButton-label]": {
                color: theme.palette.primary.main
            }
        },
        "& div[class*=MuiAccordionSummary-root]": {
            "& span[class*=MuiIconButton-label]": {
                transition: "color 0.2s linear"
            }
        }
    },
    summaryTitle: {
        transition: "font-size 0.2s ease-in-out, color 0.2s linear",
    },
    detailsText: {
        "& a": {
            textDecoration: "underline",
            color: theme.palette.primary.main
        }
    }
}))