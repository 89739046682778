import softwareCatalogImgL from '../../../assets/images/plugin_icons/backstage-software-catalog-light.svg'
import softwareCatalogImgD from '../../../assets/images/plugin_icons/backstage-software-catalog-dark.svg'

import softwareTemplatesImgL from '../../../assets/images/plugin_icons/backstage-software-templates-light.svg'
import softwareTemplatesImgD from '../../../assets/images/plugin_icons/backstage-software-templates-dark.svg'

import techDocsImgL from '../../../assets/images/plugin_icons/backstage-techdocs-light.svg'
import techDocsImgD from '../../../assets/images/plugin_icons/backstage-techdocs-dark.svg'

import backstageSearchImgL from '../../../assets/images/plugin_icons/backstage-search-platform-light.svg'
import backstageSearchImgD from '../../../assets/images/plugin_icons/backstage-search-platform-dark.svg'

import techRadarImgL from '../../../assets/images/plugin_icons/backstage-techdocs-light.svg'
import techRadarImgD from '../../../assets/images/plugin_icons/backstage-techdocs-dark.svg'

import costInsightsImgD from '../../../assets/images/cost-insights.png'
import grafanaIconD from '../../../assets/images/grafana-icon.svg'
import gitlabIconL from '../../../assets/images/gitlab-icon.svg'
import pagerDuryIconL from '../../../assets/images/pagerduty-icon.svg'

import shortcutsImgL from '../../../assets/images/plugin_icons/shortcuts-logo-light.svg'
import shortcutsImgD from '../../../assets/images/plugin_icons/shortcuts-logo-dark.svg'

import bazaarImg from '../../../assets/images/plugin_icons/bazaar.svg';

import toolboxImg from '../../../assets/images/plugin_icons/toolbox-logo.png';

import msFormsImg from '../../../assets/images/plugin_icons/microsoft-forms.png';

interface ThemedImageObject {
    dark: string,
    light: string
}

export interface ImageListInterface{
    [key: string]: ThemedImageObject
}

const backstageImageList:ImageListInterface = {
    "Software Catalog": {
        "light": softwareCatalogImgL,
        "dark": softwareCatalogImgD
    },
    "Software Templates": {
        "light": softwareTemplatesImgL,
        "dark": softwareTemplatesImgD
    },
    "TechDocs": {
        "light": techDocsImgL,
        "dark": techDocsImgD
    },
    "Backstage Search": {
        "light": backstageSearchImgL,
        "dark": backstageSearchImgD
    },
    "Cost Insights": {
        "light": '',
        "dark": costInsightsImgD
    },
    "Gitlab": {
        "light": gitlabIconL,
        "dark": ''
    },
    "Grafana": {
        "light": '',
        "dark": grafanaIconD
    },
    "PagerDuty": {
        "light": pagerDuryIconL,
        "dark": ''
    },
    "Tech Radar": {
        "light": techRadarImgL,
        "dark": techRadarImgD
    },
    "Shortcuts": {
        "light": shortcutsImgL,
        "dark": shortcutsImgD
    },
    "Bazaar": {
        "light": bazaarImg,
        "dark": bazaarImg
    },
    "Toolbox": {
        "light": toolboxImg,
        "dark": toolboxImg
    },
    "Microsoft Forms": {
        "light": msFormsImg,
        "dark": msFormsImg
    }
}

export default backstageImageList;