import React from 'react';
import { Page, Content, InfoCard } from "@backstage/core-components";
import { Box, Grid, makeStyles } from "@material-ui/core";
import { HomePageStyles } from '../../../../assets/styles/HomePageStyles';
import { FAQData } from '../../assets/data'
import { CustomAccordionItem, CustomTransition } from '../../../customUIComponents';

export const FAQAccordionStyles = makeStyles((theme) => ({
    faqQuestions : {
        counterReset: "css-counter 0",
        "&  div[class*=accordionStyle]  p[class*=summaryTitle]": {
            counterIncrement: "css-counter 1",
            "&::before": {
                content: "'Q'counter(css-counter)'.'",
                fontSize: '0.6rem',
                color: theme.palette.text.secondary,
                paddingRight: '5px'
            }
        }
    }
}))

export const FaqPage = () => {
    const classes = HomePageStyles();
    const faqClasses = FAQAccordionStyles();

    return (
        <div className={classes.backstagePageOverride}>
            <Page themeId="home">
                <Content>
                    <Box sx={{ flexGrow: 1 }} >
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <InfoCard
                                    title={"FAQ"}
                                    subheader={"Frequently Asked Questions"}
                                >
                                    <div className={faqClasses.faqQuestions}>
                                        {FAQData.map((item, index) => (
                                            <CustomTransition type="slide" direction='up' appear={true} in={true} timeout={400} delay={index * 50} key={index}>
                                                <div>
                                                    <CustomAccordionItem summary={item.question} details={item.answer} number={index} />
                                                </div>
                                            </CustomTransition>
                                        ))}
                                    </div>
                                </InfoCard>
                            </Grid>
                        </Grid>
                    </Box>
                </Content>
            </Page>
        </div>
    )
}