import {makeStyles} from "@material-ui/core";

export const HomePageLogoStyles = makeStyles(theme => ({
    container: {
        margin: theme.spacing(-5, 5),

    },
    header: {
        color: 'whitesmoke',
        backgroundImage: 'linear-gradient(to bottom right, #007FA3, black)',
    },
    svg: {
        width: 'auto',
        height: 100,
    },
    path: {
        fill: '#7df3e1'
    },
    img: {
        marginRight: "auto",
        marginLeft:"auto",
        display: "block",
        textAlign: "center",
        width: "60%",
        height: "150px",

    }
}));