import React from "react";
import { Card, CardActions, CardContent, CardMedia, Link } from "@material-ui/core";
import { Button } from "@backstage/core-components";
import { truncate } from "../../../lib/HelperFunctions";
import { PluginCardStyles } from "../../../assets/styles";

interface PluginCardProps {
    children?: React.ReactNode;
    title: string;
    author: string;
    authorUrl: string;
    category: string;
    description: string;
    documentation: string;
    iconUrl?: string;
    pluginRoute: string;
    npmPackageName: string;
    order: number;
    buttonText?: string;
}

export const PluginCard = (props: PluginCardProps) => {
    const classes = PluginCardStyles();

    return (
        <Card classes={{ root: classes.pluginCard }} key={props.order}>
            <CardContent className={classes.pluginCardHeader}>
                <CardMedia
                    className={classes.iconLogo}
                    component="img"
                    src={props.iconUrl}
                    alt={props.title}
                >
                </CardMedia>
                <CardContent classes={{ root: classes.pluginCardTitle }}>
                    <h3>{props.title}</h3>
                    <p className={classes.pluginCardAuthor}>by {<Link classes={{ root: classes["a:hover"] }} href={props.authorUrl}>{props.author}</Link>}</p>
                    <span className={classes.pluginCardChipOutlined}>{props.category}</span>
                </CardContent>
            </CardContent>
            <CardContent classes={{ root: classes.pluginCardBody }}>
                {truncate(props.description)}
            </CardContent>
            <CardActions>
                <Button color="primary" to={props.pluginRoute}>
                    {props.buttonText? props.buttonText : 'Explore'}
                </Button>
            </CardActions>

        </Card>
    )
}