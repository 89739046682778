import React from 'react';
import { Content, Page, InfoCard } from '@backstage/core-components';
import { SearchContextProvider } from "@backstage/plugin-search-react";
import { PavedRoadCard } from "./PavedRoadCard";

export const PavedRoadPage = () => {

    return (
        <SearchContextProvider>
            <Page themeId="home" >
                <Content>
                    <InfoCard
                        title="Paved Road Services"
                        subheader={"on ramp"}
                        deepLink={{ title: 'Learn more from the Backstage Plugin Marketplace', link: 'https://backstage.io/plugins' }}
                    >
                        <PavedRoadCard />
                    </InfoCard>
                </Content>

            </Page>
        </SearchContextProvider>
    );
};