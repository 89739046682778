import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import React from 'react';
import { CustomAccordionStyles } from '../../../assets/styles';

// Interfaces
export interface CustomAccordionItemProps {
    summary: React.ReactNode,
    details: React.ReactNode,
    number: number
}

export const CustomAccordionItem = (props: CustomAccordionItemProps) => {

    const classes = CustomAccordionStyles()
    
    return (
        <Accordion className={classes.accordionStyle}>
        <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls={"panel"+props.number+"a-content"}
            id={"panel"+props.number+"a-header"}
        >
            <Typography className={classes.summaryTitle}>{props.summary}</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Typography color='textSecondary' className={classes.detailsText}>
                {props.details}
            </Typography>
        </AccordionDetails>
    </Accordion>
    )
}