import React from 'react';
import { Header, ItemCardGrid, Page, Tabs } from '@backstage/core-components';
import { SearchContextProvider } from "@backstage/plugin-search-react";
import { PluginCard } from '../customUIComponents';
import { PaperLink } from '../customUIComponents';
import { PluginCardStyles } from '../../assets/styles';
import { getThemedImage } from '../../lib/HelperFunctions';

// Import Plugin Data and Images
// Backstage and Thirdparty
import backstageCards from "./data/backstageCards.json";
import backstageImageList from './data/backstageCardsImages';

// Internal Plugins
import internalCards from "./data/internalCards.json"
import internalImageList from './data/internalCardsImages';



export const PluginPage = () => {

    const classes = PluginCardStyles();

    const pluginTabs = [
        {
            label: "Backstage & Thirdparty",
            content:
                <>
                    <ItemCardGrid classes={{ root: classes.pluginGrid }} >
                        {backstageCards.sort((a,b) => a.title > b.title ? 1 : -1).map((card, index) => (
                            <PluginCard
                                title={card.title}
                                author={card.author}
                                authorUrl={card.authorUrl}
                                category={card.category}
                                description={card.description}
                                documentation={card.documentation}
                                iconUrl={getThemedImage(backstageImageList[card.title]?.light, backstageImageList[card.title]?.dark)}
                                pluginRoute={card.pluginRoute}
                                npmPackageName={card.npmPackageName}
                                order={card.order}
                                key={index}
                            />
                        ))}
                    </ItemCardGrid>
                    <PaperLink
                        text='Learn more from Backstage Plugin Market Place'
                        link='https://backstage.io/plugins'
                        target='_blank'
                    />
                </>
        },
        {
            label: "Pearson Internal",
            content:
                <>
                    <ItemCardGrid classes={{ root: classes.pluginGrid }} >
                        {internalCards.sort((a,b) => a.title > b.title ? 1 : -1).map((card, index) => (
                            <PluginCard
                                title={card.title}
                                author={card.author}
                                authorUrl={card.authorUrl}
                                category={card.category}
                                description={card.description}
                                documentation={card.documentation}
                                iconUrl={getThemedImage(internalImageList[card.title].light, internalImageList[card.title].dark)}
                                pluginRoute={card.pluginRoute}
                                npmPackageName={card.npmPackageName}
                                order={card.order}
                                key={index}
                            />
                        ))}
                    </ItemCardGrid>
                    <PaperLink
                        text='Learn more from the Pearson Backstage Blog'
                        link='https://backstage.pearsondev.com/blog'
                        target='_self'
                    />
                </>
        }
    ]

    return (
        <SearchContextProvider>
            <Page themeId="home">
                <Header title="Plugins" subtitle="Installed"></Header>
                <Tabs tabs={pluginTabs} />
            </Page>
        </SearchContextProvider>

    );
};