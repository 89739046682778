import React from 'react';
import { Route } from 'react-router';
import { PearsonLightTheme, PearsonDarkTheme, PearsonBlueTheme } from './theme/Themes'
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { orgPlugin } from '@backstage/plugin-org';
import { SearchPage } from '@backstage/plugin-search';
import { TechRadarPage } from '@backstage/plugin-tech-radar';
import {
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { apis } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { searchPage } from './components/search/SearchPage';
import { Root } from './components/Root';

import { AlertDisplay, OAuthRequestDialog } from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { FlatRoutes } from '@backstage/core-app-api';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { PermissionedRoute } from '@backstage/plugin-permission-react';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';

import { HomepageCompositionRoot} from '@backstage/plugin-home'
import { SignInPage } from '@backstage/core-components';
import { microsoftAuthApiRef} from "@backstage/core-plugin-api";
import { CostInsightsPage } from '@backstage/plugin-cost-insights';
import { PopPage } from '@internal/plugin-pop';
import { PluginPage } from './components/plugin/PluginPage';
import { PavedRoadPage } from './components/pavedRoad/PavedRoadPage';

import { GettingStartedPage } from '@internal/plugin-getting-started';
import { BlogPage } from '@internal/plugin-blog';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { WbSunny, Brightness2, Assistant } from '@material-ui/icons';

import { HomePage } from "./components/home/pages/HomePage";

// Import Plugins
import { shortcutsPlugin } from "./plugins"
import { QetaPage } from '@drodil/backstage-plugin-qeta';
import { BazaarPage } from '@backstage/plugin-bazaar';
import { AnnouncementsPage } from '@k-phoen/backstage-plugin-announcements';
import { ToolboxPage } from '@drodil/backstage-plugin-toolbox';
import { PagerdutyServicesPage, PagerdutyServiceAssociationPage } from '@pearsontechnology/plugin-pagerduty-services';

const app = createApp({
  apis,
  plugins: [shortcutsPlugin],
  themes: [{
    id: 'pearson-light-theme',
    title: 'Light',
    variant: 'light',
    icon: <WbSunny />,
    Provider: ({children}) => (
      <ThemeProvider theme={PearsonLightTheme}>
        <CssBaseline>{children}</CssBaseline>
      </ThemeProvider>
    )

  },
  {
    id: 'pearson-dark-theme',
    title: 'Dark',
    variant: 'dark',
    icon: <Brightness2 />,
    Provider: ({children}) => (
      <ThemeProvider theme={PearsonDarkTheme}>
        <CssBaseline>{children}</CssBaseline>
      </ThemeProvider>
    )

  },
  {
    id: 'pearson-blue-theme',
    title: 'Pearson',
    variant: 'light',
    icon: <Assistant />,
    Provider: ({children}) => (
      <ThemeProvider theme={PearsonBlueTheme}>
        <CssBaseline>{children}</CssBaseline>
      </ThemeProvider>
    )

  }
],
  components: {
    SignInPage: props => (
        <SignInPage
            {...props}
            auto
            provider={{
                id: 'azure-auth-provider',
                title: 'Microsoft',
                message: 'Sign in using AzureAD',
                apiRef: microsoftAuthApiRef,
            }}
        />
    ),
  },
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
});

const AppProvider = app.getProvider();
const AppRouter = app.getRouter();

const routes = (
  <FlatRoutes>
      <Route
          path = "/*"
          element={<HomepageCompositionRoot children={<HomePage/>}/>}
      />

    <Route path = "/plugin" element={<HomepageCompositionRoot/>}>
        <PluginPage />
    </Route>
      <Route path = "/paved-road" element={<HomepageCompositionRoot/>}>
          <PavedRoadPage />
      </Route>
    <Route path="/catalog" element={<CatalogIndexPage />} />
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/docs" element={<TechDocsIndexPage />} />
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      <TechDocsAddons>
        <ReportIssue />
      </TechDocsAddons>
    </Route>
    <Route path="/create" element={<ScaffolderPage />} />
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route
      path="/tech-radar"
      element={<TechRadarPage width={1500} height={800} id={"tech-radar"} title={"Pearson Tech Radar"}  />}
    />
    <Route
      path="/security-radar"
      element={<TechRadarPage width={1500} height={800} id={"security-radar"} title={"Pearson Security Radar"}/>}
    />
    <PermissionedRoute
      path="/catalog-import"
      permission={catalogEntityCreatePermission}
      element={<CatalogImportPage />}
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route path="/catalog-graph" element={<CatalogGraphPage />} />
    <Route path="/cost-insights" element={<CostInsightsPage />} />
    <Route path="/pop" element={<PopPage />}/>
    <Route path="/getting-started" element={<GettingStartedPage />}/>
    <Route path="/blog" element={<BlogPage />}/>
    <Route path="/qeta" element={<QetaPage title="Memory Leak" subtitle={"Ask technical questions to your fellow Pearson engineers"}/>} />
    <Route path="bazaar" element={<BazaarPage />} />
    <Route path="/announcements" element={<AnnouncementsPage />} />
    <Route path="/toolbox" element={<ToolboxPage />} />
    <Route path="/pagerduty-services" element={<PagerdutyServicesPage />} />
    <Route path="/pagerduty-services/association/:serviceName/:serviceType/:serviceId" element={(<PagerdutyServiceAssociationPage />)} />
  </FlatRoutes>
);

const App = () => (
  <AppProvider>
    <AlertDisplay />
    <OAuthRequestDialog />
    <AppRouter>
      <Root>{routes}</Root>
    </AppRouter>
  </AppProvider>
);

export default App;
