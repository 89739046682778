import { BackstageOverrides } from "@backstage/core-components";
import { BackstageTheme } from "@backstage/theme";

export const createThemeOverrides = (theme: BackstageTheme) : BackstageOverrides => {

    return {
        BackstageSidebarPage: {
            root: {
                '& nav h5': {
                    'color': theme.palette.sidebarSubMenu.title.color
                },
                '& nav h5 ~ div > a:hover': {
                    'color': theme.palette.sidebarSubMenu.hover.color
                },
                '& nav h5 ~ div > button:hover': {
                    'color': theme.palette.sidebarSubMenu.hover.color
                },
                '& nav h5 ~ div > button ~ div > a:hover > p': {
                    'color': theme.palette.sidebarSubMenu.hover.color
                },

                // Shortcuts plugin style overrides
                '& .plugin-shortcuts-scroll': {
                    'display': 'contents'        
                },
                '& .plugin-shortcuts-scroll > div:has(a > div)': {
                    'min-height': '120px',
                },
                '& div:has([aria-label="Add Shortcuts"]) div.MuiAvatar-root': {
                    'background-color': theme.palette.navigation.indicator,
                    'filter': 'none'
                },

                // Homepage Overrides
                '& div[class*=backstagePageOverride] div[class*=BackstageHeaderTabs-tabsWrapper]+article[class*=BackstageContent-root]': {
                    padding: 0
                }
            }
        },
        BackstageTabBar: {
            indicator: {
                backgroundColor: theme.palette.primary.main
            }
        },
        BackstageTab: {
            root: {
                width: '100%'
            }
        },
        BackstageInfoCard: {
            headerSubheader: {
                paddingTop: 0
            }
        }
    }
}