import React from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';
import { NavigateNext } from '@material-ui/icons';
import { PaperLinkStyles } from '../../../assets/styles';


export const PaperLink = (props: { text: string; link: string; target: "_blank"|"_self" }) => {

    const classes = PaperLinkStyles()
    return (
        <a href={props.link} target={props.target}>
            <Paper className={classes.paper}>
                <Grid
                    container
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                    style={{ padding: "10px 30px" }}>
                    <Typography variant='subtitle2'>{props.text}</Typography>
                    <NavigateNext className={classes.navigateIcon}/>
                </Grid>
            </Paper>
        </a>
    )
}