import { Link } from '@backstage/core-components';
import React from 'react';
import { DemoCardProps } from '../../../customUIComponents/Cards/DemoCard';
import catalogDemo from "../images/catalog-demo.png";

export const DemoData: DemoCardProps[] = [
    {
        id: "introduction-to-backstage",
        title: "Introduction to Backstage",
        content:
            <>
                Watch the videos below to get an introduction to Backstage and to see how we use different plugins to customize our internal version of Backstage at Pearson.
                <br /><br />
                Join us in the presentation videos below to see the core features of Backstage. For more, join us in our <Link to={"/about"}>community channels</Link>
                <br /><br />
                To explore the UI and basic features of Backstage firsthand, start by browsing the different sections of this platform. Alternatively, you can use the <Link to={"/getting-started"}>getting started</Link> section once you are ready to dive in!
            </>,
        image: catalogDemo,
        order: 1
    },
    {
        id: "introduction-to-software-catalog",
        title: "Introduction to Software Catalog",
        content:
            <>
                The Software Catalog is one of the core features of Backstage. In this live demo recording, Andrew Bagan, gives a brief introduction to the software catalog and its capabilities.
                <br /><br />
                The Software Catalog is a centralized system that keeps track of ownership and metadata for all software in our ecosystem.
                <br /><br />
                The catalog is built around the concept of a metadata yaml files that are stored with your code. Any kind of software can be registered within Backstage. Even if that software is not maintained by Pearson (SaaS offerings for example). It still provides value to create components to track ownership.

            </>,
        src: "https://dimwo3wpzqk4p.cloudfront.net/catalog-demo.mkv",
        order: 2
    },
    {
        id: "introduction-to-techdocs",
        title: "Introduction to TechDocs",
        content:
            <>
                TechDocs and the concept of "docs like code" is one of the core features of Backstage. In this live demo recording, Andrew Bagan, gives a brief introduction to TechDocs and shows how simple it is to get started!
                <br /><br />
                Everyone needs documentation, no one wants to create it, and worst of all no one can ever find it. Backstage follows a <Link to={"https://www.docslikecode.com/"}>docs like code</Link> approach. See how!
                <br /><br />
                By writing documentation in Markdown files right alongside your code this makes documentation easier to create, maintain, find, and use.

            </>,
        src: "https://dimwo3wpzqk4p.cloudfront.net/techdocs-demo.mkv",
        order: 3
    },
    {
        id: "introduction-to-software-templates",
        title: "Introduction to Software Templates",
        content:
            <>
                Software Templates are one of the core features of Backstage. In this live demo recording, Andrew Bagan, gives a brief introduction to the power of these templates.
                <br /><br />
                You shouldn't have to copy paste old projects to create a new one. Also, you shouldn't have to search for a quick start guide. Instead, just go to Backstage and be up and running in minutes.
                <br /><br />
                Software Templates give engineers the ability to spin up varying types of projects in a standardized way. Every. Single. Time.

            </>,
        src: "https://dimwo3wpzqk4p.cloudfront.net/software-templates-demo.mkv",
        order: 4
    },
]