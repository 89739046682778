import {createRouteRef, createSubRouteRef} from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  id: 'getting-started',
});
export const catalogTabRouteRef = createSubRouteRef({
  id: 'catalog-tab',
  parent: rootRouteRef,
  path: '/catalog'
});
export const softwareTemplateRouteRef = createSubRouteRef({
 id: 'software-template-tab',
 parent: rootRouteRef,
 path: '/software-template'
});
export const overviewTabRouteRef = createSubRouteRef({
 id: 'overview-tab',
 parent: rootRouteRef,
 path: '/overview'
});
export const techDocsTabRouteRef = createSubRouteRef({
 id: 'tech-docs-tab',
 parent: rootRouteRef,
 path: '/tech-docs'
});