import React, { FunctionComponent, useEffect, useState } from 'react';
import { Collapse, Fade, Grow, Slide, Zoom } from '@material-ui/core';

interface CustomTransitionProps {
    type: 'collapse' | 'fade' | 'grow' | 'slide' | 'zoom',
    delay: number,
    in: boolean,
    direction?: 'down' | 'left' | 'right' | 'up'
    appear?: boolean,
    timeout?: number | { enter?: number, exit?: number, appear?: number },
    children: React.ReactElement<any, any>
};

const transitionComponents = {
    collapse: Collapse,
    fade: Fade,
    grow: Grow,
    slide: Slide,
    zoom: Zoom
};

// Adds delay variable to MaterialUI's Transition components
export const CustomTransition: FunctionComponent<CustomTransitionProps> = (props) => {
    const delay = props.delay;
    const In = props.in;
    const TransitionType = transitionComponents[props.type];
    const [isIn, setIsIn] = useState(In && delay === 0)

    useEffect(() => {
        if (delay > 0) {
            setTimeout(() => setIsIn(true), delay)
        }
    })

    if(typeof TransitionType !== 'undefined'){
        return (
            <TransitionType
                in={isIn}
                appear={props.appear}
                timeout={props.timeout ? props.timeout : 0}
                direction={props.direction}
            >{props.children}</TransitionType>
        )
    }

    return <></>
}