import React from 'react';

import { Header, HeaderLabel, RoutedTabs } from "@backstage/core-components";
import pearsonLogoDark from "../../../assets/images/PearsonLogo_Horizontal_W_RGB.svg";
import { Route } from "react-router-dom";


const routes = [
    {
        path:"/",
        title:"Home",
        children: <Route path="/*"  />
    },
    {
        path:"/demos",
        title:"Demos",
        children: <Route path="*/demos"  />
    },
    {
        path:"/faq",
        title:"FAQ",
        children: <Route path="*/faq"  />
    },
    {
        path:"/contact",
        title:"Contact",
        children: <Route path="*/contact" />
    },
    {
        path:"/about",
        title:"About",
        children: <Route path="*/about" />
    }
]

export const HomePageHeader = ({ classes }: {classes?: any}) => {
   return (
       <>

            <Header title={<img alt="Pearson logo" src={pearsonLogoDark} className={classes.headerLogo}/>} pageTitleOverride='Home'>
                <HeaderLabel label="Owner" value="CRND"/>
                <HeaderLabel label="Lifecycle" value="Beta"/>
                <HeaderLabel label="Version" value="0.6.8"/>
            </Header>

           <RoutedTabs
               routes={routes}
           />
       </>
   )
}