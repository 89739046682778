import React from 'react';
import { Grid, Slide } from '@material-ui/core';
import { HomePageSearchBar } from '@backstage/plugin-search';
import { Content, Page } from '@backstage/core-components';
import { HomePageStarredEntities } from '@backstage/plugin-home';
import { SearchContextProvider } from "@backstage/plugin-search-react";
import { HomePageStyles } from "../../../../assets/styles/HomePageStyles";
import { Footer } from "../../Footer";
import { HomePageHeader } from "../../Header";
import { useLocation } from "react-router-dom";
import { FaqPage } from "../FaqPage";
import { ContactPage } from "../ContactPage";
import { DemoPage } from "../DemoPage";
import { AboutPage } from "../AboutPage";

// Plugins }
import { BazaarOverviewCard } from '@backstage/plugin-bazaar';
import { AnnouncementsCard } from '@k-phoen/backstage-plugin-announcements';
import { NewAnnouncementBanner } from '@k-phoen/backstage-plugin-announcements';
// Custom UI Components
import { CustomTransition, HomeCard } from '../../../customUIComponents';

// Home Page Content
import { HomePageData } from '../../assets/data';



export const HomePage = () => {
    const classes = HomePageStyles();

    const currentRoute = useLocation();
    return (
        <SearchContextProvider>
            <div className={classes.backstagePageOverride}>

                <Page themeId="home">
                    <HomePageHeader classes={classes} />
                    {currentRoute.pathname === "/" ?
                        <Content>
                            <Grid container justifyContent="center" spacing={2} style={{ paddingBottom: '40px' }}>
                                <Grid item md={12}>
                                    <NewAnnouncementBanner />
                                </Grid>

                                <Grid container item xs={8} alignItems="center" direction="row">
                                    <HomePageSearchBar
                                        classes={{ root: classes.searchBar }}
                                        placeholder="Search"
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} justifyContent="center">
                                <Grid item container xs={12} md={8} lg={10}>
                                    {HomePageData.map((item, index) => (
                                        <CustomTransition type='slide' direction='up' appear={true} in={true} timeout={400} delay={index*100} key={index}>
                                            <Grid item xs={12} md={6} style={{display: 'flex'}}>

                                                <HomeCard
                                                    title={item.title}
                                                    subtitle={item.subtitle}
                                                    image={item.image}
                                                    content={item.content}
                                                    link={item.link}
                                                    linkText={item.linkText}
                                                />
                                            </Grid>
                                        </CustomTransition>
                                    ))}
                                    <Grid item xs={6}>
                                        <BazaarOverviewCard order='latest'/>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <AnnouncementsCard  max={2}/>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={4} lg={2}>
                                    <Slide direction='up' appear={true} in={true} timeout={250}>
                                        <div className={classes.starredEntity}>
                                            <HomePageStarredEntities
                                                title={"Your Favorites"}
                                            />
                                        </div>
                                    </Slide>

                                </Grid>

                                {/*<Grid item xs={12} >*/}
                                {/*    <BazaarOverviewCard title='My Orgs Projects' order='random' fullWidth fullHeight />*/}
                                {/*</Grid>*/}
                            </Grid>
                            <Footer description={""} title={""}/>
                        </Content> :
                        currentRoute.pathname === "/faq" ? <FaqPage /> :
                        currentRoute.pathname === "/contact" ? <ContactPage /> :
                        currentRoute.pathname === "/demos" ? <DemoPage /> :
                        currentRoute.pathname === "/about" ? <AboutPage /> : null}

                </Page>
            </div>

        </SearchContextProvider>

    );
};