import { useTheme } from "@material-ui/core/styles";

export const truncate = (text: string) => {
    return text.length > 170 ? text.substr(0, 170) + '...' : text;
}

// Get the relevant image based on the theme
export const getThemedImage = (light?: any, dark?: any) => {

    // Get Current Theme
    const theme = useTheme()
    if (theme.palette.themeName == 'dark'){
        if (!dark){
            return light;
        }
        return dark;
    } else {
        if (!light){
            return dark;
        }
        return light;
    }
}