import { createPlugin, createRoutableExtension } from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const popPlugin = createPlugin({
  id: 'pop',
  routes: {
    root: rootRouteRef,
  },
});

export const PopPage = popPlugin.provide(
  createRoutableExtension({
    name: 'PopPage',
    component: () =>
      import('./components/PopPage').then(m => m.PopPage),
    mountPoint: rootRouteRef,
  }),
);
