/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import pearsonLogoWhite from '../../assets/images/PearsonLogo_Horizontal_W_RGB.svg';
import pearsonLogoBlack from '../../assets/images/PearsonLogo_Horizontal_Blk_RGB.svg';


const LogoFull = () => {
  let theme = useTheme()
  return (
    <div>
      <img src={theme.palette.themeName != "light" ? pearsonLogoWhite : pearsonLogoBlack} height="40px" />

    </div>
  );
};

export default LogoFull;
