import React from 'react';
import { Page, InfoCard } from '@backstage/core-components';
import { Box, Grid, ListItem, ListItemIcon, ListItemText, Typography, List } from "@material-ui/core";
import { Link, Content } from "@backstage/core-components";
import { HomePageStyles } from '../../../../assets/styles/HomePageStyles';

// Logos/Icons
import GitlabIcon from '../../../../assets/images/gitlab-icon.svg'
import SlackIcon from '../../../../assets/images/Slack-icon.svg'
import MSTeamsIcon from '../../../../assets/images/msTeams-icon.svg'

export const ContactPage = () => {
    const classes = HomePageStyles();

    return (
        <div className={classes.backstagePageOverride}>
            <Page themeId="home">
                <Content>
                    <Box sx={{ flexGrow: 1 }} >
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <InfoCard
                                    title={"Contact Us"}
                                    subheader={"Pearson Backstage Authors"}
                                >
                                    <Typography paragraph>
                                        If you are looking for a way to contact us please join the communities below (slack, teams). If you are interested in contributing then check out our <Link to={"https://backstage.pearsondev.com/docs/default/Component/backstage"}>documentation</Link> on how to do so. We look forward to hearing from you and watching our community grow!
                                    </Typography>
                                    <List className={classes.contactList}>
                                        <Link to={"https://gitlab.com/pearsontechnology/IO/cdnr/products/pearson-backstage/backstage"}>
                                            <ListItem>
                                                <ListItemIcon>
                                                    <img src={GitlabIcon} width={24} className={classes.contactLogo} />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary="Gitlab"
                                                />
                                            </ListItem>
                                        </Link>
                                        <Link to={"https://join.slack.com/share/enQtNDMxMzkwMzc5MjA4NC05Zjg2MmI2NzYwMjczOTIxMDM0MWJiMDc4YzAxOWUyZGVjY2Q5NWJlYWEwZTZmOWY1ZWRmYmVmMWI0YjM0NzNh"}>
                                            <ListItem>
                                                <ListItemIcon>
                                                    <img src={SlackIcon} width={24} className={classes.contactLogo} />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary="Slack"
                                                />
                                            </ListItem>
                                        </Link>
                                        <Link to={"https://teams.microsoft.com/l/channel/19%3aafa59bbfa2df4738867763c1f3b4baee%40thread.tacv2/Backstage?groupId=812351a2-0899-4efd-a7d2-85c58e24b0c2&tenantId=8cc434d7-97d0-47d3-b5c5-14fe0e33e34b"}>
                                            <ListItem>
                                                <ListItemIcon>
                                                    <img src={MSTeamsIcon} width={24} className={classes.contactLogo} />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary="Microsoft Teams"
                                                />
                                            </ListItem>
                                        </Link>
                                    </List>
                                </InfoCard>
                            </Grid>

                        </Grid>
                    </Box>
                </Content>
            </Page>
        </div>

    )
}