// import { ImageListInterface } from './backstageCardsImages'

// Load Images
import pearsonObservabilityPipelineImg from '../../../assets/images/pop-logo-svg-vector.svg'
import pagerDutyIconL from "../../../assets/images/pagerduty-icon.svg";

interface ThemedImageObject {
    dark: string,
    light: string
}

export interface ImageListInterface{
    [key: string]: ThemedImageObject
}

const internalImageList:ImageListInterface = {
    "Pearson Observability Pipeline": {
        "light": pearsonObservabilityPipelineImg,
        "dark": ''
    },
    "PagerDuty Services": {
        "light": pagerDutyIconL,
        "dark": ''
    }
}

export default internalImageList;