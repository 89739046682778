/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
    RadarRing,
    RadarQuadrant,
    RadarEntry,
    TechRadarLoaderResponse,
    TechRadarApi,
} from '@backstage/plugin-tech-radar';
import {SecurityRadar} from "./PearsonSecurityRadar";

const rings = new Array<RadarRing>();
rings.push({ id: 'use', name: 'USE', color: '#93c47d' });
rings.push({ id: 'trial', name: 'TRIAL', color: '#93d2c2' });
rings.push({ id: 'assess', name: 'ASSESS', color: '#fbdb84' });
rings.push({ id: 'hold', name: 'HOLD', color: '#efafa9' });

const quadrants = new Array<RadarQuadrant>();
quadrants.push({ id: 'infrastructure', name: 'Infrastructure' });
quadrants.push({ id: 'frameworks', name: 'Frameworks' });
quadrants.push({ id: 'languages', name: 'Languages' });
quadrants.push({ id: 'process', name: 'Process' });

const entries = new Array<RadarEntry>();
entries.push({
    timeline: [
        {
            moved: 0,
            ringId: 'use',
            date: new Date('2020-08-06'),
            description:
                'Adopted for both frontend and backend services. Most frontend services are using a JS framework. Backend using node.',
        },
    ],
    url: 'https://www.javascript.com/',
    key: 'javascript',
    id: 'javascript',
    title: 'JavaScript',
    quadrant: 'languages',
    description:
        'Adopted for both frontend and backend services. Most frontend services are using a JS framework. Backend using node.',
});
entries.push({
    timeline: [
        {
            moved: -1,
            ringId: 'use',
            date: new Date('2020-08-06'),
            description:
                'Adopted for both frontend and backend services. Most frontend services are using a JS framework and TS becomes a viable option then. TS is adopted for CDK and backend code as well.',
        },
    ],
    url: 'https://www.typescriptlang.org/',
    key: 'typescript',
    id: 'typescript',
    title: 'TypeScript',
    quadrant: 'languages',
    description:
        'Adopted for both frontend and backend services. Most frontend services are using a JS framework and TS becomes a viable option then. TS is adopted for CDK and backend code as well.',
});
entries.push({
    timeline: [
        {
            moved: -1,
            ringId: 'use',
            date: new Date('2020-08-06'),
            description:
                'Adopted for production products',
        },
    ],
    url: 'https://www.oracle.com/java/technologies/downloads/',
    key: 'java',
    id: 'java',
    title: 'Java',
    quadrant: 'languages',
    description:
        'Adopted for production products',
});
entries.push({
    timeline: [
        {
            moved: -1,
            ringId: 'use',
            date: new Date('2020-08-06'),
            description:
                'Adopted for production products',
        },
    ],
    url: 'https://www.python.org/',
    key: 'python',
    id: 'python',
    title: 'Python',
    quadrant: 'languages',
    description:
        'Adopted for production products',
});
entries.push({
    timeline: [
        {
            moved: -1,
            ringId: 'use',
            date: new Date('2020-08-06'),
            description:
                'Adopted for production products',
        },
    ],
    url: 'https://go.dev/',
    key: 'go',
    id: 'go',
    title: 'Go',
    quadrant: 'languages',
    description:
        'Adopted for production products',
});
entries.push({
    timeline: [
        {
            moved: -1,
            ringId: 'trial',
            date: new Date('2020-08-06'),
            description:
                'Trial of using OCaml',
        },
    ],
    url: 'https://www.rust-lang.org/',
    key: 'OCaml',
    id: 'OCaml',
    title: 'OCaml',
    quadrant: 'languages',
    description:
        'Trial - using OCaml because Chad likes to talk about it',
});
entries.push({
    timeline: [
        {
            moved: -1,
            ringId: 'assess',
            date: new Date('2020-08-06'),
            description:
                'assessed for lambda functionality',
        },
    ],
    url: 'https://www.rust-lang.org/',
    key: 'rust',
    id: 'rust',
    title: 'Rust',
    quadrant: 'languages',
    description:
        'assessed for lambda functionality',
});
entries.push({
    timeline: [
        {
            moved: -1,
            ringId: 'hold',
            date: new Date('2020-08-06'),
            description:
                'holding as we do not use cases',
        },
    ],
    url: 'https://www.rust-lang.org/',
    key: 'carbon',
    id: 'carbon',
    title: 'Carbon',
    quadrant: 'languages',
    description:
        'holding as we do not use cases',
});
entries.push({
    timeline: [
        {
            moved: -1,
            ringId: 'hold',
            date: new Date('2020-08-06'),
            description:
                'holding as we do not want anymore PHP development',
        },
    ],
    url: 'https://www.php.net/',
    key: 'php',
    id: 'php',
    title: 'PHP',
    quadrant: 'languages',
    description:
        'holding as we do not want anymore PHP development',
});
entries.push({
    timeline: [
        {
            moved: 1,
            ringId: 'use',
            date: new Date('2020-08-06'),
            description:
                'Adopted as a production frontend framework',
        },
    ],
    url: 'https://angularjs.org/',
    key: 'angular',
    id: 'angular',
    title: 'Angular',
    quadrant: 'frameworks',
    description:
        'Adopted as a production frontend framework',
});
entries.push({
    timeline: [
        {
            moved: 0,
            ringId: 'use',
            date: new Date('2020-08-06'),
        },
    ],
    url: 'https://reactjs.org/',
    key: 'react',
    id: 'react',
    title: 'React',
    quadrant: 'frameworks',
});

entries.push({
    timeline: [
        {
            moved: 1,
            ringId: 'use',
            date: new Date('2020-08-06'),
            description:
                'Adopted as a production frontend framework',
        },
    ],
    url: 'https://angularjs.org/',
    key: 'spring',
    id: 'spring',
    title: 'Spring',
    quadrant: 'frameworks',
    description:
        'Adopted as a production frontend framework',
});
entries.push({
    timeline: [
        {
            moved: 1,
            ringId: 'trial',
            date: new Date('2020-08-06'),
            description:
                'trial for new applications that want a unique js framework',
        },
    ],
    url: 'https://vuejs.org/',
    key: 'vue',
    id: 'vue',
    title: 'Vue',
    quadrant: 'frameworks',
    description:
        'trial for new applications that want a unique js framework',
});
entries.push({
    timeline: [
        {
            moved: 1,
            ringId: 'assess',
            date: new Date('2020-08-06'),
            description:
                'assessing for new applications that want to write frontend python',
        },
    ],
    url: 'https://flask.palletsprojects.com/en/2.2.x/',
    key: 'flask',
    id: 'flask',
    title: 'Flask',
    quadrant: 'frameworks',
    description:
        'assessing for new applications that want to write frontend python',
});
entries.push({
    timeline: [
        {
            moved: 1,
            ringId: 'assess',
            date: new Date('2020-08-06'),
            description:
                'assessing for new applications that want to write frontend python',
        },
    ],
    url: 'https://flask.palletsprojects.com/en/2.2.x/',
    key: 'django',
    id: 'django',
    title: 'Django',
    quadrant: 'frameworks',
    description:
        'assessing for new applications that want to write frontend python',
});
entries.push({
    timeline: [
        {
            moved: 1,
            ringId: 'hold',
            date: new Date('2020-08-06'),
            description:
                'hold is in place for Laravel as it is a PHP framework',
        },
    ],
    url: 'https://laravel.com/',
    key: 'laravel',
    id: 'laravel',
    title: 'Laravel',
    quadrant: 'frameworks',
    description:
        'hold is in place for Laravel as it is a PHP framework',
});
entries.push({
    timeline: [
        {
            moved: 0,
            ringId: 'use',
            date: new Date('2020-08-06'),
        },
    ],
    url: '#',
    key: 'code-reviews',
    id: 'code-reviews',
    title: 'Code Reviews',
    quadrant: 'process',
});
entries.push({
    timeline: [
        {
            moved: 0,
            ringId: 'trial',
            date: new Date('2020-08-06'),
        },
    ],
    url: '#',
    key: 'peer-kudos-program',
    id: 'peer-kudos-program',
    title: 'Peer Kudos Program',
    quadrant: 'process',
});
entries.push({
    timeline: [
        {
            moved: 0,
            ringId: 'assess',
            date: new Date('2020-08-06'),
        },
    ],
    url: '#',
    key: 'mob-programming',
    id: 'mob-programming',
    title: 'Mob Programming',
    quadrant: 'process',
});
entries.push({
    timeline: [
        {
            moved: 0,
            ringId: 'use',
            date: new Date('2020-08-06'),
        },
    ],
    url: '#',
    key: 'docs-like-code',
    id: 'docs-like-code',
    title: 'Docs-like-code',
    quadrant: 'process',
});
entries.push({
    timeline: [
        {
            ringId: 'hold',
            date: new Date('2020-08-06'),
        },
    ],
    url: '#',
    key: 'force-push',
    id: 'force-push',
    title: 'Force push to master',
    quadrant: 'process',
});
entries.push({
    timeline: [
        {
            ringId: 'use',
            date: new Date('2020-08-06'),
            description: 'Adoption of Jenkins should be considered paved road',
        },
    ],
    url: 'https://github.com',
    key: 'jenkins',
    id: 'jenkins',
    title: 'Jenkins',
    quadrant: 'infrastructure',
});
entries.push({
    timeline: [
        {
            ringId: 'use',
            date: new Date('2020-08-06'),
            description: 'Adoption of Jenkins should be considered paved road',
        },
    ],
    url: 'https://github.com',
    key: 'gitlab',
    id: 'gitlab',
    title: 'Gitlab CICD',
    quadrant: 'infrastructure',
});
entries.push({
    timeline: [
        {
            ringId: 'use',
            date: new Date('2020-08-06'),
            description: 'Adoption of Terraform should be considered paved road',
        },
    ],
    url: 'https://github.com',
    key: 'terraform',
    id: 'terraform',
    title: 'Terraform',
    quadrant: 'infrastructure',
});
entries.push({
    timeline: [
        {
            ringId: 'use',
            date: new Date('2020-08-06'),
            description: 'Adoption of Terragrunt should be considered normal',
        },
    ],
    url: 'https://github.com',
    key: 'terragrunt',
    id: 'terragrunt',
    title: 'Terragrunt',
    quadrant: 'infrastructure',
});
entries.push({
    timeline: [
        {
            ringId: 'trial',
            date: new Date('2020-08-06'),
            description: 'Adoption of Terragrunt should be considered normal',
        },
    ],
    url: 'https://github.com',
    key: 'terraform-cloud',
    id: 'terraform-cloud',
    title: 'Terraform Cloud',
    quadrant: 'infrastructure',
});
entries.push({
    timeline: [
        {
            ringId: 'assess',
            date: new Date('2020-08-06'),
            description: 'Adoption of Terragrunt should be considered normal',
        },
    ],
    url: 'https://github.com',
    key: 'spacelift',
    id: 'spacelift',
    title: 'Spacelift',
    quadrant: 'infrastructure',
});
entries.push({
    timeline: [
        {
            ringId: 'hold',
            date: new Date('2020-08-06'),
            description: 'Adoption of Terragrunt should be considered normal',
        },
    ],
    url: 'https://github.com',
    key: 'env0',
    id: 'env0',
    title: 'Env0',
    quadrant: 'infrastructure',
});

export const techRadar: TechRadarLoaderResponse = {
    entries,
    quadrants,
    rings,

};

export class PearsonTechRadar implements TechRadarApi{
    async load(id:string | undefined): Promise<TechRadarLoaderResponse> {
        if (id === "security-radar") {
            return SecurityRadar;
        } else {
            return techRadar;
        }

    }
}