import { createPlugin, createRoutableExtension } from '@backstage/core-plugin-api';

import { rootRouteRef, catalogTabRouteRef, softwareTemplateRouteRef, overviewTabRouteRef, techDocsTabRouteRef } from './routes';

export const gettingStartedPlugin = createPlugin({
  id: 'getting-started',
  routes: {
      root: rootRouteRef,
      catalog: catalogTabRouteRef,
      software: softwareTemplateRouteRef,
      overview: overviewTabRouteRef,
      techdocs: techDocsTabRouteRef,

  },
});

export const GettingStartedPage = gettingStartedPlugin.provide(
  createRoutableExtension({
    name: 'GettingStartedPage',
    component: () =>
      import('./components/GettingStartedPage').then(m => m.GettingStartedPage),
    mountPoint: rootRouteRef,
  }),
);
