import {createRouteRef, createSubRouteRef} from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  id: 'blog',
});
export const introRouteRef = createSubRouteRef({
  id: 'introducing-backstage',
  parent: rootRouteRef,
  path: '/introducing-backstage',
});
export const releaseNotesRouteRef = createSubRouteRef( {
  id: 'release-notes',
  parent: rootRouteRef,
  path: '/release-notes',
});
export const gettingStartedRouteRef = createSubRouteRef( {
  id: 'getting-started',
  parent: rootRouteRef,
  path: '/getting-started',
});



