import { createPlugin, createRoutableExtension } from '@backstage/core-plugin-api';

import {gettingStartedRouteRef, introRouteRef, releaseNotesRouteRef, rootRouteRef} from './routes';

export const blogPlugin = createPlugin({
  id: 'blog',
  routes: {
        root: rootRouteRef,
        intro: introRouteRef,
        release: releaseNotesRouteRef,
        started: gettingStartedRouteRef,
  },
});

export const BlogPage = blogPlugin.provide(
  createRoutableExtension({
    name: 'BlogPage',
    component: () =>
      import('./components/BlogPage').then(m => m.BlogPage),
    mountPoint: rootRouteRef,
  }),
);
