import {makeStyles} from "@material-ui/core";

export const HomePageStyles = makeStyles(theme => ({
    searchBar: {
        display: 'flex',
        maxWidth: '60vw',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[1],
        padding: '8px 0',
        borderRadius: '50px',
        margin: 'auto',
    },
    container: {
        margin: theme.spacing(-5, 5),

    },
    header: {
        color: 'whitesmoke',
        backgroundImage: 'linear-gradient(to bottom right, #007FA3, black)',
    },
    svg: {
        width: 'auto',
        height: 100,
    },
    path: {
        fill: '#7df3e1'
    },
    img: {
        marginRight: "auto",
        marginLeft:"auto",
        display: "block",
        textAlign: "center",
        width: "55%",
        height: "200px",
    },
    headerLogo: {
        marginRight: "auto",
        marginLeft:"auto",
        display: "block",
        width: "250px",
        padding: "20px"
    },
    mainContent: {
        backgroundColor: "#121212",
        background: 'linear-gradient(90.49deg, #121212 15.36%, #282828 70.44%)',
    },
    paragraph: {
        fontFamily: "Helvetica Neue",
        color: "#e4e4e4",
        fontSize: "130%",
    },
    stripeBottom: {
        marginBottom: 'calc(-2vw - 10px)',
        // clipPath: 'polygon(0% 0%, 100% 0%, 100% calc(100% - 2vw - 10px), 0% 100%)',
        background: 'linear-gradient(90.49deg, #121212 15.36%, #282828 70.44%)'
    },
    stripeLast: {
        marginBottom: 'calc(-2vw - 10px)',
        background: 'linear-gradient(90.49deg, #121212 15.36%, #282828 70.44%)'
    },
    reverseStripeBottom: {
        marginBottom: 'calc(-2vw - 10px)',
        clipPath: 'polygon(0% 0%, 100% 0%, 100% calc(100% - 2vw - 10px), 0% 100%)',
        background: 'linear-gradient(#282828 70.44%, 90.49deg, #121212 15.36%)'
    },
    stripe: {
        marginTop: 'calc(-2vw - 12px)',
        marginBottom: 'calc(-2vw - 12px)',
        clipPath: 'polygon(0% calc(2vw + 10px),100% 0px,100% calc(100% - 2vw - 10px),0% 100%)',
        background: '#121212'
    },
    bgBlackGrey: {
        background: 'linear-gradient(90.49deg, #121212 15.36%, #282828 70.44%)'
    },
    bgBlack: {
        background: '#121212'
    },
    boxContainer: {
        maxWidth: 'auto',
        flex: 1,
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'space-around',
        alignItems: 'center',

    },
    graphicsContainer: {
        flex: '0 0 auto',
        width: '700px',
        margin: '20px auto',
        maxWidth: 'calc(100vw - 80px)',
        paddingBottom: '80px',
    },
    graphic: {
        position: 'absolute',
        display: 'block',
        maxWidth: 'none',
        height: 'auto',
    },
    mediaFrameBox: {
        width: '95%',
        height: '500px',
        padding: '20px 0',
        flex: '0 0 auto',
        alignSelf: 'flex-start',
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
    mediaFrame: {
        width: '100%',
        height: '100%',
    },
    starredEntity: {
        position: "sticky",
        top: 15,
        "& div[class*=MuiCardContent-root-]": {
            maxHeight: "426px",
            overflowY: "auto",
            "& div[class*=MuiListItem-gutters]": {
                paddingLeft: 0
            }
        }
    },
    contactList:{
        width: "210px",
        "& a:hover img[class*=contactLogo]": {
            filter: "none"
        }
    },
    contactLogo: {
        filter: "grayscale(1)",
        transition: "filter 0.3s ease-in-out"
    },

    // Page Overrides
    // Remove scroll
    backstagePageOverride: {
        '&>main': {
            'overflow-y': 'unset'
        }
    }
}));