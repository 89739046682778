import {
    BackstageTheme,
    createTheme,
    darkTheme,
    genPageTheme,
    lightTheme,
    shapes,
} from '@backstage/theme';

import {
    offWhite,
    chalkWhite,
    pearsonBlue,
    graphiteGrey,
    marineTurquoise,
    grassGreen,
    midnightBlue,
    whiteGrey,
    mistGrey,
    skyBlue
} from './PearsonColors'
import { createThemeOverrides } from './ThemeOverrides';

// Page Banners
const pageTheme = {
    home: genPageTheme({ colors: [midnightBlue, pearsonBlue], shape: shapes.wave }),
    documentation: genPageTheme({ colors: [midnightBlue, pearsonBlue], shape: shapes.wave }),
    tool: genPageTheme({ colors: [marineTurquoise, midnightBlue], shape: shapes.round }),
    service: genPageTheme({
        colors: [marineTurquoise, midnightBlue],
        shape: shapes.wave,
    }),
    website: genPageTheme({
        colors: [marineTurquoise, midnightBlue],
        shape: shapes.wave,
    }),
    library: genPageTheme({
        colors: [marineTurquoise, midnightBlue],
        shape: shapes.wave,
    }),
    other: genPageTheme({ colors: [grassGreen, marineTurquoise], shape: shapes.wave }),
    app: genPageTheme({ colors: [marineTurquoise, midnightBlue], shape: shapes.wave }),
    apis: genPageTheme({ colors: [midnightBlue, marineTurquoise], shape: shapes.wave }),
}


// Theme Templates
export const PearsonLightThemeTemplate = createTheme({
    palette: {
        ...lightTheme.palette,
        themeName: 'light',
        primary: {
            light: marineTurquoise,
            main: pearsonBlue,
            dark: midnightBlue
        },
        secondary: {
            light: whiteGrey,
            main: mistGrey,
            dark: graphiteGrey
        },
        navigation: {
            background: lightTheme.palette.grey["200"],
            indicator: pearsonBlue,
            color: lightTheme.palette.text.secondary,
            selectedColor: lightTheme.palette.text.secondary,
            navItem: {
                hoverBackground: lightTheme.palette.grey["300"]
            },
            submenu: {
                background: lightTheme.palette.grey["300"],
            }
        },
        sidebarSubMenu: {
            hover: {
                color: darkTheme.palette.text.primary
            },
            title: {
                color: lightTheme.palette.text.primary
            }
        },
        sidebarDivider: lightTheme.palette.divider
    },
    defaultPageTheme: 'home',
    pageTheme: pageTheme,
});

export const PearsonDarkThemeTemplate = createTheme({
    palette: {
        ...darkTheme.palette,
        themeName: 'dark',
        primary: {
            main: marineTurquoise,
            light: skyBlue,
            dark: pearsonBlue
        },
        secondary: PearsonLightThemeTemplate.palette.secondary,
        navigation: {
            ...darkTheme.palette.navigation,
            indicator: pearsonBlue,
            navItem: {
                hoverBackground: "rgb(64, 64, 64)"
            }
        },
        sidebarSubMenu: {
            hover: {
                color: darkTheme.palette.text.primary
            },
            title: {
                color: darkTheme.palette.text.primary
            }
        },
        sidebarDivider: darkTheme.palette.divider
    },
    pageTheme: pageTheme,
    defaultPageTheme: 'home'
})

export const PearsonBlueThemeTemplate = createTheme({
    palette: {
        ...PearsonLightThemeTemplate.palette,
        themeName: 'pearson-blue',
        navigation: {
            background: midnightBlue,
            indicator: pearsonBlue,
            color: offWhite,
            selectedColor: chalkWhite,
            navItem: {
                hoverBackground: pearsonBlue
            },
            submenu: {
                background: graphiteGrey
            }
        },
        sidebarSubMenu: {
            hover: {
                color: darkTheme.palette.text.primary
            },
            title: {
                color: darkTheme.palette.text.primary
            }
        },
        sidebarDivider: pearsonBlue
    },
    pageTheme: pageTheme,
    defaultPageTheme: 'home',
})

// Themes
export const PearsonLightTheme:BackstageTheme = {
    ...PearsonLightThemeTemplate,
    overrides: {
        ...PearsonLightThemeTemplate.overrides,
        ...createThemeOverrides(PearsonLightThemeTemplate)
    }
}

export const PearsonDarkTheme:BackstageTheme = {
    ...PearsonDarkThemeTemplate,
    overrides: {
        ...PearsonDarkThemeTemplate.overrides,
        ...createThemeOverrides(PearsonDarkThemeTemplate)
    }
}

export const PearsonBlueTheme:BackstageTheme = {
    ...PearsonBlueThemeTemplate,
    overrides: {
        ...PearsonBlueThemeTemplate.overrides,
        ...createThemeOverrides(PearsonBlueThemeTemplate)
    }
}