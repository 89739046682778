import {makeStyles} from "@material-ui/core";

// Homepage > Home Card
export const HomeCardStyles = makeStyles(()=>({
    HomeCard: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column"
    },
}))

// Homepage > Demo Card
export const DemoCardStyles = makeStyles(()=>({
    DemoCard: {
        display: "flex"
    },
    DemoCardTitle: {
        width: "48%"
    },
    DemoCardMedia: {
        padding: "16px",
        borderRadius: "20px"
    }
}))

// Plugin Card
export const PluginCardStyles = makeStyles((theme) => ({
    pluginGrid: {
        display: 'grid',
        gridGap: '1rem',
        gridTemplateColumns: 'repeat(4, 1fr)',
        gitAutoRows: '1fr',
    },
    pluginCard: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        color: theme.palette.text.primary,
    },
    pluginCardHeader: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        maxHeight: 'fit-content',
        minHeight: 'fit-content',
    },
    pluginCardTitle: {
        color: theme.palette.text.primary,
        verticalAlign: 'top',
        margin: '8px 0 0',
        padding: '0px 0px 0px 8px',
        '& h3': {
            margin: 0
        }
    },
    pluginCardAuthor: {
        margin: '0 0 5px 0',
        color: theme.palette.text.secondary,
    },
    pluginCardChipOutlined: {
        fontSize: 'small',
        borderRadius: '16px',
        padding: '2px 8px',
        border: '1px solid '+theme.palette.primary.main,
        color: theme.palette.primary.main,
    },pluginCardBody: {
        padding: "0 16px",
    },
    "a:hover": {
        color: theme.palette.text.secondary,
    },
    iconLogo: {
        float: 'left',
        margin: '10px 16px 0px 8px',
        width: '65px',
        height: 'auto'
    }
}))