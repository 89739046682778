// Pearson Brand colors
// Blues
export const skyBlue = "#94E7EA";
export const marineTurquoise = "#12B2A6";
export const pearsonBlue = "#007FA3";
export const midnightBlue = "#003057";

// Neutral
export const inkBlack = "#000000";
export const chalkWhite = "#ffffff";

// Shades
export const offWhite = "#f8f8f8";
export const whiteGrey = "#EFF0F0"
export const mistGrey = "#DFE1E1";
export const graphiteGrey = "#505759"

// Lights
export const brightOrange = "#FFBB1C";
export const limeGreen = "#D2DB0E";

// Midtones
export const coralPink = "#FF757A";
export const freshGreen = "#84BD00";

// Darks
export const royalPurple = "#9E007E";
export const grassGreen = "#008638";

// Error Hex
export const errorRed = "#ff3333"