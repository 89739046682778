import React from "react";
import {ItemCardGrid} from "@backstage/core-components";
import {
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Collapse,
     Link,
    Typography,
} from "@material-ui/core";
import clsx from "clsx";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from "@material-ui/core/IconButton";
import { ServiceCardStyles } from "../../../assets/styles/styles";
import popLogo from "../../../assets/images/pop-logo-svg-vector.svg";
import splunkLogo from "../../../assets/images/splunk-black-white-bg.png";
import grafanaIcon from '../../../assets/images/grafana-icon.svg';
import pdIcon from '../../../assets/images/pagerduty-icon.svg';
import pIcon from '../../../assets/images/p_icon.png';
import cards from "../data/services/cards.json";
// import {useImage} from "../hooks/useImage";

const truncate = (text: string) =>
    text.length > 170 ? text.substr(0, 170) + '...' : text;

const jenkinsLogo = "https://img.icons8.com/color/1600/jenkins.png";
const newRelicLogo = "https://www.mulesoft.com/sites/default/files/2018-10/New_relic.png";
const logos = [popLogo, newRelicLogo, splunkLogo, jenkinsLogo, pIcon, pIcon, pdIcon, grafanaIcon]

const cardInfo = [
    "Various information and links for relevant resources given down in this section"
]

export const PavedRoadCard = () => {
    const classes = ServiceCardStyles();
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    return (
        <ItemCardGrid classes={{root: classes.pluginGrid }}>
            {[...Array(cards.length).keys()].map(index => (

                <Card classes={{ root: classes.pluginCard }} key={index}>
                    <CardContent className={ classes.pluginCardHeader}>

                        <CardMedia classes={{root: classes.pluginCardImage}}
                                   className={classes.img}
                                   component="img"
                                   src={logos[index]}
                                   alt={cards[index].title}
                        >
                        </CardMedia>

                        <CardContent classes={{root: classes.pluginCardInfo}}>
                            <CardContent classes={{root: classes.pluginCardTitle}}>
                                <h3>{cards[index].title}</h3>
                                <p className={classes.pluginCardAuthor}>by { <Link classes={{root: classes["a:hover"]}} href={cards[index].authorUrl}>{cards[index].author}</Link>}</p>
                                <span className={classes.pluginCardChipOutlined}>{cards[index].category}</span>
                            </CardContent>
                        </CardContent>
                    </CardContent>
                    <CardContent classes={{root: classes.pluginCardBody}}>
                        {truncate(cards[index].description)}
                    </CardContent>
                    <CardActions classes={{root: classes.pluginCardFooter}}>
                        <IconButton
                            className={clsx(classes.expand, {
                                [classes.expandOpen]: expanded,
                            })}
                            onClick={handleExpandClick}
                            aria-expanded={expanded}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </IconButton>
                        <Collapse in={expanded} timeout="auto" unmountOnExit>
                            <Typography paragraph>
                                {cardInfo}
                            </Typography>
                            <Typography>
                                {<Link>Link to resources</Link>}
                            </Typography>

                        </Collapse>
                    </CardActions>
                </Card>
            ))}
        </ItemCardGrid>

    )};