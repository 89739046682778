import React from 'react';
import { Page, InfoCard } from '@backstage/core-components';
import {
    Box,
    Grid,
    Typography,
} from "@material-ui/core";
import { Content, Link, WarningPanel } from "@backstage/core-components";
import { HomePageStyles } from '../../../../assets/styles/HomePageStyles';

export const AboutPage = () => {
    const classes = HomePageStyles();

    return (
        <div className={classes.backstagePageOverride}>
            <Page themeId="home">
                <Content>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <InfoCard
                                    title="About Backstage"
                                    subheader="Empowering Teams, Streamlining Development"
                                >
                                    <Typography paragraph>
                                        Welcome to Pearson Backstage, where collaboration meets
                                        efficiency in software development. Backstage is more than
                                        just a platform; it's a centralized ecosystem designed to
                                        empower every team member involved in the software
                                        development lifecycle.
                                    </Typography>
                                </InfoCard>
                            </Grid>

                            <Grid item xs={12}>
                                <InfoCard
                                    title="Key Features"
                                >
                                    <Typography paragraph>
                                        <strong>Service Catalog:</strong> Easily discover and manage
                                        all your services.
                                    </Typography>
                                    <Typography paragraph>
                                        <strong>TechDocs:</strong> A powerful documentation tool that allows
                                        teams to create and maintain comprehensive documentation for their services.
                                    </Typography>
                                    <Typography paragraph>
                                        <strong>Plugin Architecture:</strong> Backstage's extensible plugin
                                        architecture enables customization and integration with various tools and services.
                                    </Typography>
                                    <Typography paragraph>
                                        <strong>Visibility and Collaboration:</strong> Backstage provides visibility
                                        into the entire software development process, fostering collaboration by providing
                                        a shared space for communication and reducing communication barriers.
                                    </Typography>
                                    <Typography paragraph>
                                        <strong>Scalability and Adaptability:</strong> Backstage is designed to scale
                                        with our organization’s needs, allowing teams to embrace agile practices and respond
                                        effectively to evolving project requirements.
                                    </Typography>
                                </InfoCard>
                            </Grid>

                            <Grid item xs={12}>
                                <InfoCard
                                    title="How Backstage Works for You"
                                >
                                    <Typography paragraph>
                                        Backstage adapts to your workflow, providing tools for
                                        developers, project managers, and SRE teams alike.
                                        Discover a better way to build, collaborate, and succeed
                                        with Backstage.
                                    </Typography>
                                </InfoCard>
                            </Grid>

                            <Grid item xs={12}>
                                <InfoCard
                                    title="Introduction to Backstage"
                                >
                                    <Typography paragraph>
                                        For a detailed introduction to Backstage, read our{' '}
                                        <Link to="/blog/introducing-backstage">
                                            introductory blog post
                                        </Link>.
                                    </Typography>
                                </InfoCard>
                            </Grid>

                            <Grid item xs={12}>
                                <InfoCard
                                    title="Contact Us"
                                >
                                    <Typography paragraph>
                                        If you have any questions or need further assistance, feel
                                        free to reach out to us through the{' '}
                                        <Link to="/contact">Contact</Link> tab.
                                    </Typography>
                                </InfoCard>
                            </Grid>

                            <Grid item xs={12}>
                                <WarningPanel
                                    title="Important Notice"
                                >
                                    Backstage is continually evolving. Keep an eye on our{' '}
                                    <Link to="/blog/release-notes">changelog</Link> for the
                                    latest updates and improvements.
                                </WarningPanel>
                            </Grid>
                        </Grid>
                    </Box>
                </Content>
            </Page>
        </div>
    );
};