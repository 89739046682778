import { Card, CardContent, CardHeader, CardMedia, Divider, Typography } from '@material-ui/core';
import React from 'react';
import { HomeCardStyles, PaperLinkStyles } from '../../../assets/styles';
import { PaperLink } from '../PaperLink/PaperLink';

export interface HomeCardProps {
    title: string,
    subtitle: string,
    image: {
        height?: number,
        src: string,
        alt?: string
    },
    content: React.ReactNode,
    link: string,
    linkText: string
}

export const HomeCard = (props: HomeCardProps) => {
    const classes = HomeCardStyles()
    const paperLinkClasses = PaperLinkStyles()
    return (
        <Card className={classes.HomeCard}>
            <CardMedia
                component='img'
                height={props.image.height ? props.image.height : 200}
                image={props.image.src}
                alt={props.image.alt ? props.image.alt : 'default'}
            />
            <CardHeader
                title={props.title}
                subheader={props.subtitle}
            />
            <Divider style={{margin: "12px 16px 0"}}/>
            <CardContent>
                <Typography variant="body1" color="textSecondary">{props.content}</Typography>
            </CardContent>
            <div className={paperLinkClasses.borderedPaperLink}>
                <PaperLink
                    link={props.link}
                    text={props.linkText}
                    target="_self"
                />
            </div>
        </Card>
    )
}