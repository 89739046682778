import { HomeCardProps } from '../../../customUIComponents/Cards/HomeCard';

// Resources
import backstageLearnGif from '../../../../assets/images/backstage_learn.gif';
import backstageDev from '../../../../assets/images/backstage_dev.png';

export const HomePageData: HomeCardProps[] = [
    {
        title: "Your Journey Begins Here",
        subtitle: "Getting Started",
        image: {
            src: backstageDev,
            alt: "Getting Started",
            height: 220
        },
        content: "Your journey to Backstage adoption should begin here. This section contains resources, videos, and guides on different topics. It has just been released, and in it's initial form, only contains guides for getting started with the core features of Backstage. At a basic level, but enough to get you on the right path.",
        link: "/getting-started",
        linkText: "Get Started"
    },
    {
        title: "Blog",
        subtitle: "Blog and release notes",
        image: {
            src: backstageLearnGif,
            alt: "Learning Dev",
            height: 220
        },
        content: "The newly released blog is a place for news, product updates, and the change log. Blogs will be released as frequently as needed. They will cover various areas, from beginner to advanced. There is something for everyone!",
        link: "/blog",
        linkText: "View Blogs"
    },
]