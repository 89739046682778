import {makeStyles} from "@material-ui/core";

export const ServiceCardStyles = makeStyles((theme) => ({
    header: {
        color: 'whitesmoke',
        backgroundImage: 'linear-gradient(to bottom right, #007FA3, black)',
    },
    pluginCard: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        color: theme.palette.text.primary,
    },
    pluginGrid: {
        display: 'grid',
        gridGap: '1rem',
        gridTemplateColumns: 'repeat(4, 1fr)',
        gitAutoRows: '1fr',
    },
    pluginCardImg: {
        float: 'left',
        margin: '0px 16px 8px 0px',
        height: '80px',
        width: '80px',
    },
    pluginCardHeader: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        maxHeight: 'fit-content',
        minHeight: 'fit-content',
    },
    pluginCardImage: {
        width: '80px',
        height: '80px',
        marginRight: '16px',
    },

    pluginCardInfo: {
        flex: 1,
    },
    pluginAddNewButton: {
        position: 'absolute',
        bottom: '16px',
        right: '0px',
    },
    buttonFilled: {
        padding: '4px 8px',
        borderRadius: '4px',
        color: theme.palette.primary.main
    },
    pluginCardChipOutlined: {
        fontSize: 'small',
        borderRadius: '16px',
        padding: '2px 8px',
        border: '1px solid '+theme.palette.primary.main,
        color: theme.palette.primary.main,
    },
    pluginCardFooter: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        marginTop: 'auto',
        minHeight: '2em',
    },
    pluginPageLayout: {
        margin: 'auto',
        maxWidth: '1430px',
        padding: '20px',
    },
    pluginPageHeader: {
        position: 'relative',
    },
    pluginCardBody: {
        padding: "0 16px",
    },
    pluginCardAuthor: {
        margin: '0 0 5px 0',
        color: theme.palette.text.secondary,
    },
    pluginCardTitle: {
        color: theme.palette.text.primary,
        verticalAlign: 'top',
        margin: '8px 0 0',
        padding: '0px 0px 0px 8px',
        '& h3': {
            margin: 0
        }
    },
    iconLogo: {
        float: 'left',
        margin: '10px 16px 0px 8px',
        width: '65px',
        height: 'auto'
    },
    "img": {
        float: 'left',
        margin: '0px 16px 8px 0px',
        height: '80px',
        width: '80px',
    },
    "img2": {
        width: '100%',
        maxWidth: '100%',
    },
    "a:hover": {
        color: theme.palette.text.secondary,
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    }
}));